<template>
  <div>
    <div v-if="dateVisible" class="date-text has-text-secondary has-text-centered" :class="smallClass">
      {{ date }}
    </div>
    <div class="message-container">
      <div v-if="isFromCurrentUser" class="is-flex is-flex-direction-column">
        <div v-if="timeVisible" class="time-text has-text-secondary has-text-right" :class="smallClass">
          {{ time }}
        </div>
        <div class="message-box my-message" :class="smallClass">
          {{ messageText }}
        </div>
      </div>

      <div class="is-flex is-flex-direction-row has-fullwidth" v-else>
        <profile-picture
          v-if="userAvatarVisible"
          :picture="userAvatar"
          :initials="initials"
          class="avatar"
          :class="smallClass"
        ></profile-picture>
        <div v-else class="empty-avatar" :class="smallClass"></div>
        <div class="is-flex is-flex-direction-column is-flex-grow-1 has-fullwidth">
          <div v-if="timeVisible" class="time-text" :class="smallClass">
            {{ time }}
          </div>
          <div class="message-box someone-message" :class="smallClass">
            {{ messageText }}
          </div>
        </div>
      </div>

      <!--      <div v-else class="columns is-mobile is-gapless">-->
      <!--        <div class="column is-narrow someone-profile-picture" :class="smallClass">-->
      <!--          <profile-picture v-if="userAvatarVisible" :picture="userAvatar" :initials="initials" class="avatar"-->
      <!--                           :class="smallClass"></profile-picture>-->
      <!--          &lt;!&ndash;          <div v-else class="empty-avatar" :class="smallClass"></div>&ndash;&gt;-->
      <!--        </div>-->
      <!--        <div class="column has-fullwidth">-->
      <!--          <div class="is-flex is-flex-direction-column">-->
      <!--            <div v-if="timeVisible" class="time-text has-text-secondary" :class="smallClass">-->
      <!--              {{ time }}-->
      <!--            </div>-->
      <!--            <div class="message-box someone-message has-background-light" :class="smallClass">-->
      <!--              {{ messageText }}-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script>
import ProfilePicture from "@/shared/components/common/profile/ProfilePicture";
import { mapGetters } from "vuex";
import { composeInboxMessageDate, composeInboxMessageTime, initials } from "@/shared/utils";

export default {
  name: "InboxMessageListItem",

  components: { ProfilePicture },
  props: {
    message: {
      type: Object,
    },

    isSmall: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters("currentUser", { currentUserId: "uuid" }),
    messageUser() {
      return this.message.user;
    },

    isFromCurrentUser() {
      return this.messageUser.user_identity_token === this.currentUserId;
    },

    userAvatarVisible() {
      return this.message.avatarVisible;
    },

    userAvatar() {
      return this.messageUser.picture;
    },

    initials() {
      return initials(this.messageUser);
    },

    messageText() {
      return this.message.message;
    },

    dateVisible() {
      return this.message.dateVisible;
    },

    date() {
      return composeInboxMessageDate(this.message.created_at);
    },

    timeVisible() {
      return this.message.timeVisible;
    },

    time() {
      return composeInboxMessageTime(this.message.created_at);
    },

    smallClass() {
      if (this.isSmall) {
        return "is-small";
      } else {
        return "";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.message-container {
  padding: 3px 20px 3px 20px;
  width: 100%;
}

.message-box {
  border-radius: 8px;
  padding: 10px;
  max-width: 40%;
  width: auto;
  white-space: pre-line;
  word-wrap: break-word;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  background-color: var(--inbox-message-someone-bg-color);
  color: var(--inbox-message-someone-text-color);

  &.my-message {
    margin-left: auto;
    background-color: var(--inbox-message-my-bg-color);
    color: var(--inbox-message-my-text-color);
  }

  &.someone-message {
    margin-right: auto;
    margin-left: 7px;
    //max-width: 60% !important;
  }

  &.is-small {
    font-style: normal;
    font-size: var(--inbox-message-text-size);
    font-weight: var(--inbox-message-text-weight);
    line-height: 155%;
    padding: 9px;
    max-width: 80% !important;
  }
}

.time-text {
  font-size: 14px;
  line-height: 19px;

  color: var(--inbox-message-date-color);
  margin-left: 7px;
  margin-bottom: 3px;

  &.is-small {
    font-size: 11px;
    line-height: 15px;
  }
}

.date-text {
  font-size: 16px;
  line-height: 22px;
  color: #828282;
  margin: 20px;

  &.is-small {
    font-size: 13px;
    line-height: 20px;
  }
}

.someone-profile-picture {
  min-width: 40px;

  &.is-small {
    min-width: 26px;
  }
}

.avatar {
  width: 40px;
  height: 40px;
  font-size: 14px;

  &.is-small {
    width: 26px;
    min-width: 26px;
    height: 26px;
    font-size: 10px;
  }
}

.empty-avatar {
  width: 40px;
  height: 40px;

  &.is-small {
    width: 27px;
    height: 27px;
  }
}
</style>
